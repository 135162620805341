import React from 'react'
import PropTypes from 'prop-types'
import BaseCard from 'src/components/Cards/BaseCard'
import { Button, Text } from 'src/components/Base'
import { graphql, useStaticQuery } from 'gatsby'
import mapMarker from './maps-and-flags.png'
import {
  cLocationCard,
  cLocationImage,
  cContactLinks,
  cEmail,
  cButtonIcon,
  cContactIconLocation,
} from './styles.module.scss'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const LocationCard = ({
  link,
  name,
  address,
  city,
  phone,
  mail,
  image,
  buttonText,
}) => {
  const data = useStaticQuery(graphql`
    query locationCard {
      wp {
        themeGeneralSettings {
          atspOptions {
            phoneAt
            phoneCh
            phoneDe
            contactIcon {
              slug
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  return (
    <BaseCard
      className={cLocationCard}
      backgroundColor="#FFFFFF"
      headerHasNoPadding
      header={
        <GatsbyImage
          layout="fullWidth"
          alt={image.altText}
          image={getImage(image.localFile.childImageSharp)}
          className={cLocationImage}
        />
      }
      body={
        <>
          <Text className={cContactLinks}>
            <h3>{name}</h3>
            {address}
            <br />
            {city}
            <br />
            <img
              className={cContactIconLocation}
              src={
                data.wp.themeGeneralSettings.atspOptions.contactIcon.localFile
                  .publicURL
              }
            />
            <br />
            <a href={'tel:' + phone}>{phone}</a>
            <br />
            <div className={cEmail}>
              <a href={'mailto:' + mail}>{mail}</a>
            </div>
          </Text>
          <Button
            color="cta"
            as="a"
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={cButtonIcon} src={mapMarker} />
            {buttonText}
          </Button>
        </>
      }
      hasBorder
    />
  )
}

LocationCard.propTypes = {
  link: PropTypes.string.isRequred,
  phone: PropTypes.string.isRequred,
  mail: PropTypes.string.isRequired,
  image: PropTypes.object.isRequred,
  name: PropTypes.string.isRequred,
  address: PropTypes.string.isRequred,
  city: PropTypes.string.isRequred,
  buttonText: PropTypes.string.isRequired,
}

export default LocationCard
