import React from 'react'
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api'
import mapstyles from './mapstyles'
import mapIcon from './mapIcon.png'

const libraries = ['localContext']
const mapContainerStyle = {
  width: '100%',
  height: '525px',
}
const center = {
  lat: 47.262691,
  lng: 11.3947,
}
const options = {
  styles: mapstyles,
  diableDefaultUI: true,
  fullscreenControl: false,
  zoomControl: false,
  streetViewControl: false,
  mapTypeControl: false,
}

export default function ContactMap() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
    libraries,
  })

  if (loadError) return 'Error loading maps'
  if (!isLoaded) return 'Loading Maps'

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={7}
        center={center}
        options={options}
      >
        <Marker
          icon={{
            url: mapIcon,
            scaledSize: new window.google.maps.Size(30, 41),
          }}
          position={{ lat: 47.2624876, lng: 11.3976031 } /* Innsbruck */}
        />
        <Marker
          icon={{
            url: mapIcon,
            scaledSize: new window.google.maps.Size(30, 41),
          }}
          position={{ lat: 48.176731, lng: 16.402479 } /* Wien */}
        />
        <Marker
          icon={{
            url: mapIcon,
            scaledSize: new window.google.maps.Size(30, 41),
          }}
          position={{ lat: 47.3500068, lng: 7.8985056 } /* Olten */}
        />
      </GoogleMap>
    </div>
  )
}
