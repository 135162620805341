import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import {
  Section,
  Heading,
  Wysiwyg,
  Container,
  FlexboxRow,
} from 'src/components/Base'
import LocationCard from 'src/components/Cards/LocationCard'
import ContactMap from 'src/components/GoogleMap'
import { Hero, AboutUs, ContactPerson } from 'src/sections'
import Seo from 'src/components/Seo'

const Contact = ({ data }) => {
  const page = data.wpPage
  const hero = page.atspHero
  const contactPage = page.atspContactPage
  const locations = contactPage.locations
  const locationRepeater = locations.locationRepeater
  const buttonText = locations.buttonText
  const fiftyFifty = contactPage.contactFiftyfifty
  const contact = contactPage.contactContactPerson
  const contactData = contact.contactPerson
  const portrait =
    contactData.atspcontacts.portrait.localFile.childImageSharp
      .gatsbyImageData || undefined
  const altText = contactData.atspcontacts.portrait.altText || undefined

  return (
    <>
      <Seo
        title={page.seo.title}
        locale={page.language.locale}
        description={page.seo.metaDesc}
      />
      <Hero imageData={hero.heroImage.localFile} heading={hero.heroHeading} />
      <Section hasBackground>
        <Container>
          <Heading as="h2" size={2} isCentered color="dark">
            {locations.heading}
          </Heading>
          <Wysiwyg isCentered>{locations.introText}</Wysiwyg>
        </Container>
        <FlexboxRow stretchCards>
          <LocationCard
            name={locationRepeater[0].name}
            address={locationRepeater[0].address}
            city={locationRepeater[0].city}
            link={locationRepeater[0].buttonLink}
            image={locationRepeater[0].image}
            text={locationRepeater[0].text}
            phone={locationRepeater[0].phone}
            mail={locationRepeater[0].mail}
            buttonText={buttonText}
          />
          <LocationCard
            name={locationRepeater[1].name}
            address={locationRepeater[1].address}
            city={locationRepeater[1].city}
            link={locationRepeater[1].buttonLink}
            image={locationRepeater[1].image}
            text={locationRepeater[1].text}
            phone={locationRepeater[1].phone}
            mail={locationRepeater[1].mail}
            buttonText={buttonText}
          />
          <LocationCard
            name={locationRepeater[2].name}
            address={locationRepeater[2].address}
            city={locationRepeater[2].city}
            link={locationRepeater[2].buttonLink}
            image={locationRepeater[2].image}
            text={locationRepeater[2].text}
            phone={locationRepeater[2].phone}
            mail={locationRepeater[2].mail}
            buttonText={buttonText}
          />
        </FlexboxRow>
      </Section>
      <Section hasBackground>
        <ContactMap />
      </Section>
      <Section>
        <AboutUs
          heading={fiftyFifty.heading}
          text={fiftyFifty.text}
          hasButton
          buttonText={fiftyFifty.buttonText}
          buttonLink={fiftyFifty.buttonLink.uri}
        />
      </Section>
      <ContactPerson
        heading={contact.heading}
        name={contactData.title}
        extensionNumber={contactData.atspcontacts.contactData.extension}
        email={contactData.atspcontacts.contactData.email}
        portrait={portrait}
        altText={altText}
        hasBackground
      />
    </>
  )
}

Contact.propTypes = {
  content: PropTypes.string,
}

Contact.defaultProps = {
  content: '',
}

export const pageQuery = graphql`
  query ContactById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      language {
        locale
      }
      seo {
        title
        metaDesc
      }
      atspHero {
        heroHeading
        heroImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            url
          }
        }
      }
      atspContactPage {
        locations {
          heading
          introText
          buttonText
          locationRepeater {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              altText
            }
            name
            address
            city
            phone
            mail
            buttonLink {
              url
            }
          }
        }
        contactContactPerson {
          heading
          contactPerson {
            ... on WpKontakt {
              title
              id
              atspcontacts {
                portrait {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                    name
                  }
                }
                contactData {
                  email
                  extension
                }
              }
            }
          }
        }
        contactFiftyfifty {
          heading
          text
          buttonText
          buttonLink {
            ... on WpPage {
              id
              uri
            }
          }
        }
      }
    }
  }
`

export default Contact
